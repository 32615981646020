import React, {Component} from 'react'
import Background from 'shared/background'
import Icon from 'components/icon'
import Link from 'shared/link'
import style from './list.module.scss'

class ProviderList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			specialty: this.props.specialty,
			locationName: this.props.locationName,
			locationID: this.props.location,
			name: '',
			data: this.props.data,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.data !== prevState.data) {
			this.forceUpdate();
			console.log('Force Update Complete');
		}
	}

	componentDidMount() {
		this.setState({
			data: this.props.data,
		})
		if (this.props.specialty) {
			this.setState({
				name: this.props.specialty,
			})
		} else if (this.props.locationName) {
			this.setState({
				name: this.props.locationName,
			})
		}
	}

	render() {
		return (
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className={style.header}>
						<h3>{this.props.title ? this.props.title : 'Our Headline Physicians & Providers'}</h3>
						<p className={!this.props.description ? 'hide' : ''}>{this.props.description}</p>
						<p style={{textTransform: 'uppercase'}}><Link to="/providers/" state={this.state}>{`VIEW ALL ${this.state.name} PROVIDERS`}</Link></p>
					</div>
				</div>
				<div className="grid-x medium-up-2">
					{this.props.data.map((provider, index) => (
						<div key={index} id={provider.firstName} className={[style.provider, "cell"].join(' ')}>
							<Link to={provider.link}>
								<div className={style.providerInner}>
									<div className={style.photoFrame}>
										<div className={style.image}>
											<Background className={style.photo} crop="faces,center" ar="1:1" width={800} lowQuality={false} image={(provider.photo) ? provider.photo : '/userfiles/dr-blank.jpg'} />
										</div>
									</div>
									<div className={style.info}>
										<div className={style.name}>{provider.firstName}{provider.middleName ? ` ${provider.middleName} ` : ' '}{provider.nickName ? ` "${provider.nickName}" ` : ' '}{provider.lastName}{provider.title ? `, ${provider.title}` : ''}</div>
										<div className={style.specialty}>{provider.category ? `${provider.category} - ` : ''}{(provider.specialty) ? provider.specialty.join(', ') : ''}</div>
										<div className={style.commNumbers}>
											<div className={style.number}><Icon className={style.icon} icon="phone" /> {provider.phone}</div>
											<div className={(provider.fax) ? style.number : ''}>{provider.fax && <Icon className={style.icon} icon="fax" />} {(provider.fax) ? provider.fax : ''}</div>
											<div className={provider.floor ? style.number : 'hide'}>{provider.floor && <Icon className={style.icon} icon="building" />} {provider.floor}</div>
										</div>
									</div>
								</div>
							</Link>
						</div>
					))}
				</div>
				<div className="grid-x">
					<div className="cell text-center show-for-medium"><p style={{textTransform: 'uppercase'}}><Link to="/providers/" state={this.state}>{`VIEW ALL ${this.state.name} PROVIDERS`}</Link></p></div>
				</div>
			</div>
		)
	}
}
export default ProviderList;