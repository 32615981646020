import React, {Component} from 'react'
import Background from 'shared/background'
import Icon from 'components/icon'
import Link from 'shared/link'
import LocationsMap from 'components/locations/map'
import parse from 'html-react-parser';
import style from './single.module.scss';

class Location extends Component {
	constructor(props) {
		super(props);
		this.state = {
			specialtyToggle: true,
		}
		this.specialtyToggle = this.specialtyToggle.bind(this);
		this.phoneConvert = this.phoneConvert.bind(this);
	}

	specialtyToggle() {
		this.setState(prevState => ({
			specialtyToggle: !prevState.specialtyToggle,
		}));
	}

	phoneConvert(input) {
		var inputlength = input.length;
		input = input.toLowerCase();
		var phonenumber = "";
		for (let i = 0; i < inputlength; i++) {
			var character = input.charAt(i);

			switch(character) {
				case '0': phonenumber+="0";break;
				case '1': phonenumber+="1";break;
				case '2': phonenumber+="2";break;
				case '3': phonenumber+="3";break;
				case '4': phonenumber+="4";break;
				case '5': phonenumber+="5";break;
				case '6': phonenumber+="6";break;
				case '7': phonenumber+="7";break;
				case '8': phonenumber+="8";break;
				case '9': phonenumber+="9";break;
				case '-': phonenumber+="-";break;
				case  'a': case 'b': case 'c': phonenumber+="2";break;
				case  'd': case 'e': case 'f': phonenumber+="3";break;
				case  'g': case 'h': case 'i': phonenumber+="4";break;
				case  'j': case 'k': case 'l': phonenumber+="5";break;
				case  'm': case 'n': case 'o': phonenumber+="6";break;
				case  'p': case 'q': case 'r': case 's': phonenumber+="7";break;
				case  't': case 'u': case 'v': phonenumber+="8";break;
				case  'w': case 'x': case 'y': case 'z': phonenumber+="9";break;
			}
		}
		phonenumber = phonenumber.replace(/-/g, '');
		return phonenumber;
	}

	render() {
		var location = this.props.data;
		console.log(location);
		return (
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className={[style.sideWrapper, "cell medium-4 small-order-2 medium-order-1"].join(' ')}>
						<div className={[style.photoFrame, 'show-for-medium'].join(' ')}>
							<div className={style.image}>
								<Background className={style.photo} remoteImage={(location.photo) ? false : true} image={(location.photo) ? location.photo : `https://maps.googleapis.com/maps/api/staticmap?markers=${location.address.replace(/ /g,"+")}+${location.city.replace(/ /g,"+")},${location.state}+${location.zip}&zoom=16&size=300x400&key=${process.env.GOOGLE_MAPS_API_KEY}`} />
							</div>
						</div>
						{location.specialtiesPhones && <div className={[style.phonesContainer, "grid-x"].join(' ')}>
							<div className="cell">
								{/*<p className={style.clinicPhone}>Clinic Phone: <span>{location.phone}</span></p>*/}
								{location.specialtiesPhones.map((specialty, index) => (
									<p key={index} className={style.phoneRow}><span>{specialty.label}</span><span className={style.number}><Link external={true} to={`tel:+1${this.phoneConvert(specialty.number)}`}>{specialty.number}</Link></span></p>
								))}
							</div>
						</div>}
					</div>
					<div className={[style.mainWrapper, "cell medium-8 small-order-1 medium-order-2"].join(' ')}>
						<div className={[style.newPatientRow, `${(location.selfSchedulingLink) ? 'hide' : ''}`, 'grid-x'].join(' ')}>
							<div className="cell auto">
								<p className={style.intro}><span>New Patient?</span>Call to request an appointment.</p>
							</div>
							<div className="cell shrink"><Icon icon="phone" className={style.icon} /><Link to="tel:+16292553584" external={true}>629-255-3584</Link></div>
						</div>
						{/*<div className={[style.selfSchedulingRow, `${(location.selfSchedulingLink) ? '' : 'hide'}`, "grid-x"].join(' ')}>
							<div className="cell small-12">
								<Link className={[style.cta, "button"].join(' ')} to={`https://phreesia.me/HeritageMedicalAssociates`}>Schedule Appointment</Link>
							</div>
						</div>*/}
						<div className={[style.inner, "grid-x"].join(' ')}>
							<div className={[style.pageTitle, "cell small-12"].join(' ')}><h1>{location.name}</h1></div>
							{location.specialties && <div className={[style.specialties, "cell small-12"].join(' ')}>
								<div className="grid-x">
									<div onClick={this.specialtyToggle} className="cell auto">
										<h3 className={style.title}>Specialties</h3>
									</div>
									<div className="cell shrink hide-for-medium">
										<button onClick={this.specialtyToggle} className={style.specialtyToggle}>
											<Icon icon={this.state.specialtyToggle ? 'downChevron' : 'upChevron'} />
										</button>
									</div>
								</div>
								<div className={[style.specialtiesHolder, (this.state.specialtyToggle ? style.collapsed : style.expanded)].join(' ')}>
									<div className="grid-x medium-up-3">
									{location.specialties.map((specialty, index) => (
										<div className={[style.specialty, "cell"].join(' ')}>{specialty.label}</div>
									))}
									</div>
								</div>
							</div>}
							<div className={[style.selfSchedulingRow, `${(location.selfSchedulingLink) ? '' : 'hide'}`, "cell small-12"].join(' ')}>
								<div className="cell small-12">
									<Link className={[style.cta, "button"].join(' ')} to={`https://phreesia.me/HeritageMedicalAssociates`}>Schedule Appointment</Link>
								</div>
							</div>
							<div className={[style.photoFrame, location.photo ? '' : 'hide', 'hide-for-medium'].join(' ')}>
								<div className={style.image}>
									<Background className={style.photo} image={location.photo} />
								</div>
							</div>
							<div className={[style.map, "cell small-12"].join(' ')}>
								<div className={style.mapHolder}>
									<LocationsMap height='300px' data={[location]} />
								</div>
							</div>
							<div className={[style.locationInfo, "cell small-12"].join(' ')}>
								<div className="grid-x">
									<div className={[style.address, 'cell', (Object.keys(location.hours).length > 0) ? ' medium-4 large-5' : ''].join(' ')}>
										<p className={style.locName}>{location.name}</p>
										<p className={style.locAddress}>{location.address}<br />{location.floor ? parse(`${location.floor}<br />`) : ''}{location.city}, {location.state} {location.zip}</p>
									</div>
									{Object.keys(location.hours).length > 0 && <div className={[style.hours, 'cell medium-8 large-7'].join(' ')}>
										<p className={style.hoursTitle}>Hours:</p>
										<div className={style.hoursList}>
											{Object.keys(location.hours).map((key, index) => (
												<p key={index} className={style.dayHours}>{key}: {location.hours[key]}</p>
											))}
										</div>
									</div>}
								</div>
							</div>
							<div className={[style.ctaRow, "cell small-12"].join(' ')}>
								<p><Link className={[style.cta, "button"].join(' ')} to={`https://www.google.com/maps/dir//${location.name.replace(/ /g,"+")}+${location.address.replace(/ /g,"+")}+${location.city.replace(/ /g,"+")},${location.state}+${location.zip}`}><Icon className={style.directionsIcon} icon="directions" /> Get Directions</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Location;