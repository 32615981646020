import React, { Component, Fragment } from "react"
// import $ from 'jquery';
// import { Router } from "@reach/router"
import {graphql} from 'gatsby'
import Layout from 'components/layout/basic';
import Location from 'components/locations/single';
import PatientEducationController from 'controllers/blog/patienteducation';
import ProviderListController from 'controllers/providers/list';
import ProviderList from 'components/providers/list';
import SEO from 'shared/seo';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

export default class LocationsPage extends Component {
	constructor(props) {
		super(props);
		this.location = (this.props.data.allLocationsLocation && this.props.data.allLocationsLocation.edges.length ? this.props.data.allLocationsLocation.edges[0].node : null);
		this.specialties = (this.props.data.allPhysiciansSpecialty && this.props.data.allPhysiciansSpecialty.edges.length ? this.props.data.allPhysiciansSpecialty.edges : null);
		this.allCategories = (this.props.data.allPhysiciansCategory && this.props.data.allPhysiciansCategory.edges.length ? this.props.data.allPhysiciansCategory.edges : []);
		this.allProviders = (this.props.data.allPhysiciansProvider && this.props.data.allPhysiciansProvider.edges.length ? this.props.data.allPhysiciansProvider.edges : []);
		let initialData = this.processData((this.props.data.allLocationsLocation && this.props.data.allLocationsLocation.edges.length ? this.props.data.allLocationsLocation.edges[0].node : null));	
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
			specialties: this.specialties,
		}

		this.liveRefresh = this.liveRefresh.bind(this);
		this.processData = this.processData.bind(this);
	}

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}locations/location/get/?_join=true&uri=${this.location.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let location = this.processData(data[0]);
					this.setState({
						status: STATUS.LOADED,
						data: location,
					})
					console.log('Live refresh complete');
				}
			})
			.catch(err => console.log);
	}

	processData(data) {
		let newDatum = {
			id: data.id,
			name: data.name,
			address: data.address1,
			floor: data.address2,
			city: data.city,
			state: data.state,
			zip: data.zip,
			phone: data.phone,
			photo: data.photo,
			uri: data.uri,
			latitude: data.position ? data.position[0] : null,
			longitude: data.position ? data.position[1] : null,
			selfSchedulingLink: data.selfSchedulingLink,
			specialties: null,
			specialtiesPhones: null,
			hours: {},
			providers: [],
			// dept_phones: [
			// 	{
			// 		label: 'Allergy',
			// 		number: '123-456-7890'
			// 	},
			// 	{
			// 		label: 'Behavioral Medicine',
			// 		number: '123-456-7890'
			// 	},
			// 	{
			// 		label: 'Dermatology',
			// 		number: '123-456-7890'
			// 	},
			// 	{
			// 		label: 'Ear, Nose, and Throat',
			// 		number: '123-456-7890'
			// 	},
			// 	{
			// 		label: 'Endocrinology / Diabetes',
			// 		number: '123-456-7890'
			// 	},

			// ]
		}
		const specialtySort = (a, b) => {
		  if (a.label < b.label) {
		    return -1
		  }

		  if (a.label > b.label) {
		    return 1
		  }

		  return 0
		}
		if (data.Specialty && data.Specialty.length) {
			let specialties = [];
			let specialtiesPhones = [];
			data.Specialty.forEach(specialty => {
				let specialtyObj = {};
				specialtyObj.label = specialty.object.name;
				if (specialty.properties.phone) { specialtyObj.number = specialty.properties.phone };
				if (specialty.properties.phone) { specialtiesPhones.push(specialtyObj) };
				specialties.push(specialtyObj);
			});
			if (specialties.length) {
				specialties.sort(specialtySort);
				newDatum.specialties = specialties;
			} else {
				newDatum.specialties = null;
			}
			if (specialtiesPhones.length) {
				specialtiesPhones.sort(specialtySort);
				newDatum.specialtiesPhones = specialtiesPhones;
			} else {
				newDatum.specialtiesPhones = null;
			}
		}
		let locationHours = {};
		if (data.hoursMon) { locationHours.Mo = data.hoursMon };
		if (data.hoursTue) { locationHours.Tu = data.hoursTue };
		if (data.hoursWed) { locationHours.We = data.hoursWed };
		if (data.hoursThu) { locationHours.Th = data.hoursThu };
		if (data.hoursFri) { locationHours.Fr = data.hoursFri };
		if (data.hoursSat) { locationHours.Sa = data.hoursSat };
		if (data.hoursSun) { locationHours.Su = data.hoursSun };
		if (Object.keys(locationHours).length > 0) { newDatum.hours = locationHours }

		// Parse out the location providers
		if (data.Provider && data.Provider.length) {
			let providers = [];
			data.Provider.forEach(provider => {
				let providerObj = {
					firstName: provider.object.firstName,
					lastName: provider.object.lastName,
					middleName: provider.object.middleName,
					nickName: provider.object.nickName,
					title: provider.object.abbreviations ? provider.object.abbreviations.join(', ') : null,
					specialty: null,
					photo: provider.object.photo,
					link: provider.object.uri,
					phone: provider.object.directPhone,
					fax: provider.object.fax,
					specialtyIDs: [],
				};
				this.allProviders.forEach(item => {
					let randSort = Math.floor(Math.random() * 10000);
					if (item.node.randSort) {
						randSort = item.node.randSort;
					}
					if (item.node.id === provider.object.id) {
						providerObj.randSort = randSort;
					}
				});
				let physicianFloor = false;
				if (provider.object.Specialty && provider.object.Specialty.length) {
					let specialties = [];
					provider.object.Specialty.forEach(specialty => {
						if (specialty.object.id) {
							if (this.specialties.length) {
								this.specialties.forEach(item => {
									if (item.node.id === specialty.object.id) {
										specialties.push(item.node.name);
										providerObj.specialtyIDs.push(item.node.id);
									}
								});
							}
						}
					});
					if (specialties.length) {
						specialties.sort();
						providerObj.specialty  = specialties;
					} else {
						providerObj.specialty  = null;
					}
				}
				if (provider.object.Location && provider.object.Location.length) {
					provider.object.Location.forEach(location => {
						if (location.object.id === data.id) {
							if (location.properties.floor && location.properties.floor !== '') {
								providerObj.floor = location.properties.floor;
								physicianFloor = true;
							}
						}
					});
				}
				if(!physicianFloor) {
					if (data.Specialty.length) {
						data.Specialty.forEach(specialtyItem => {
							if(providerObj.specialtyIDs.length) {
								providerObj.specialtyIDs.forEach(id => {
									if (specialtyItem.object.id === id) {
										if (specialtyItem.properties) {
											if (specialtyItem.properties.floor && specialtyItem.properties.floor !== '') {
												providerObj.floor = specialtyItem.properties.floor;
											}
										}
									}
								})
							}
						})
					}
				}
				if (provider.object.Category && provider.object.Category.length) {
					let categories = [];
					provider.object.Category.forEach(provCat => {
						if (provCat.object.id) {
							if (this.allCategories.length) {
								this.allCategories.forEach(item => {
									if (item.node.id === provCat.object.id) {
										categories.push(item.node.name);
									}
								});
							}
						}
					});
					if (categories.length) {
						providerObj.category  = categories[0];
					}
				}
				providers.push(providerObj);
				// console.log(providerObj);
			});
			if (providers.length) {
				function shuffleArray(array) {
					array.sort((left, right) => (left.randSort - right.randSort));
					return array;
				}
				providers = shuffleArray(providers);
				if (providers.length > 8) {
					providers.length = 8;
				}
				newDatum.providers = providers;
			} else {
				newDatum.providers = [];
			}
		}

		return newDatum;
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<Fragment>
				<Layout>
					<Location data={this.state.data} />
					<hr />
					{this.state.data.providers.length && <Fragment><ProviderList data={this.state.data.providers} location={this.state.data.id} locationName={this.state.data.name} title={`Our ${this.state.data.name} Providers`} />
					<hr /></Fragment>}
					<PatientEducationController />
					<SEO title={`${this.state.data.name} | ${this.props.data.site.siteMetadata.title}`} />
				</Layout>
			</Fragment>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  allLocationsLocation(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        name
            address1
            address2
            city
            state
            zip
            uri
            photo
            hoursMon
            hoursTue
            hoursWed
            hoursThu
            hoursFri
            hoursSat
            hoursSun
            phone
            position
            Specialty {
              object {
            	name
            	id
              }
              properties {
              	phone
              	floor
              }
            }
            Provider {
              object {
              	id
				      	firstName
				      	lastName
				      	middleName
				      	nickName
				      	photo
				      	abbreviations
				      	uri
				      	photo
				      	directPhone
				      	Category {
				      	  object {
				      	  	id
				      	  }
				      	}
				      	Location {
				      		object {
				      			id
				      		}
				      		properties {
				      			floor
				      		}
				      	}
				      	Specialty {
				      	  object {
				      	  	id
				      	  }
				      	}
		          }
            }
	      }
	    }
	  }
	  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  allPhysiciansProvider(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        randSort
	      }
	    }
	  }
	  allPhysiciansCategory(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  site {
	    siteMetadata {
	      title
	    }
	  }
	}
`